.contenedor-slider {
  margin: 0 auto;
  overflow: hidden;
}

.contenedor-imagenes {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide-imagen {
  display: inline-block;
}

.slide-imagen img {
  max-width: 100%;
  height: auto;
}

.contenedor-slide-controles {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
}

.boton-slider {
  display: inline-block;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 7px 0px;
  background-color: #ffffff;
  outline: 1px solid #c8c8c8;
  outline-offset: -1px;
}

.boton-slider.activo {
  background-color: #2b2b2b;
  outline: 1px solid #2b2b2b;
}
