/**GRID CONTENEDOR PIE*/
.contenedor-pie {
  background-color: #2b2b2b;
  display: grid;
  grid-template-columns: 35% 15% 15% 35%;
  grid-template-areas: "pie-logo pie-empresa pie-politicas pie-registro";
  padding: 40px;
}

.pie-logo {
  grid-area: pie-logo;
}
.pie-empresa {
  grid-area: pie-empresa;
}
.pie-politicas {
  grid-area: pie-politicas;
}
.pie-registro {
  grid-area: pie-registro;
}
/**ESTILOS EN GENERAL*/

.contenedor-pie .pie-logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contenedor-pie .pie-logo img {
  width: 300px;
}

.contenedor-pie .pie-empresa,
.contenedor-pie .pie-politicas,
.contenedor-pie .pie-registro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

.contenedor-pie div h3 {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.17;
  color: #e59b9e;
}
.contenedor-pie div p,
.contenedor-pie div a {
  font-size: 13px;
  line-height: 1.7;
  margin-bottom: 10px;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .contenedor-pie {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr auto;

    grid-template-areas:
      "pie-logo pie-registro"
      "pie-empresa pie-politicas";
  }
  .contenedor-pie {
    padding: 5px 20px;
  }

  .contenedor-pie .pie-empresa {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 500px) {
  .contenedor-pie {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;

    grid-template-areas:
      "pie-logo pie-logo"
      "pie-politicas pie-empresa"
      "pie-registro pie-registro";
  }

  .contenedor-pie div form {
    align-items: center;
  }
  .contenedor-pie .pie-logo img {
    width: 160px;
  }
  
}
