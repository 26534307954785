.contenedor-politicas {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px 50px 50px;
  max-width: 920px;
  margin: 0 auto;
  width: 90%;
}

.contenedor-politicas h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
}

.contenedor-politicas p {
  text-align: center;
  line-height: 30px;
  margin-bottom: 55px;
}

.contenedor-politicas p span img {
  width: 18px;
  margin-right: 25px;
}

.contenedor-politicas ul li {
  margin-bottom: 30px;
}
