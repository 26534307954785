.slide-productos-herramienta {
  width: 100%;
  min-height: 300px;
  position: relative;
  margin: auto;
  padding: 0 30px;
}

.slide-productos-imagen {
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}
.slide-productos-imagen::-webkit-scrollbar {
  display: none;
}

.control-atras-slide,
.control-siguiente-slide {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  margin: 5px;
}

.control-siguiente-slide {
  right: 0;
}

.control-atras-slide {
  left: 0;
}
