.contenedor-perfil-cliente h4 {
  color: #999;
  font-size: 17.33px;
  margin-bottom: 10px;
}

.contenedor-perfil-cliente form {
  display: flex;
  flex-direction: column;
}

.contenedor-perfil-cliente form input[type="email"],
.contenedor-perfil-cliente form input[type="text"],
.contenedor-perfil-cliente form textarea {
  display: block;
  font-family: inherit;
  font-size: 13px;
  background-color: white;
  width: 100%;
  padding: 15px 5px 15px 5px;
  border: 1px solid #999;
  margin-bottom: 20px;
}

.contenedor-perfil-cliente form input[type="email"]:focus,
.contenedor-perfil-cliente form textarea,
.contenedor-perfil-cliente form input[type="text"]:focus {
  outline: none;
}

.contenedor-perfil-cliente form input[type="submit"] {
  padding: 15px 0 15px 0;
  font-family: inherit;
  border: 1px solid #181818;
  font-weight: 300;
  background-color: #181818;
  color: white;
  cursor: pointer;
}

.contenedor-perfil-cliente form input[type="submit"]:hover {
  background: rgb(85, 85, 85);
  color: white;
}
