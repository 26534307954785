.contenedor-paginacion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.contenedor-paginacion p {
  margin: 0px 5px;
  padding: 2px 10px;
  border-radius: 2px;
  cursor: pointer;
}
.contenedor-paginacion button {
  border: none;
  background: none;
  margin: 0px 5px;
  padding: 2px 10px;
  border-radius: 2px;
  cursor: pointer;
}
.contenedor-paginacion .boton-paginacion-activo {
  background-color: #f2f2f2;
  border: 1px solid #c8c8c8;
}

.contenedor-paginacion img {
  width: 20px;
}
