.contenedor-caracteristica {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 5px;
  border: 1px solid #999;
  font-size: 12px;
}

.contenedor-caracteristica-letra{
  display: flex;
  justify-content: center;  
}

.contenedor-caracteristica div span {
  cursor: pointer;
}

.contenedor-caracteristica  img {
  width: 20px;
}
