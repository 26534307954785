.contenedor-ingresar-fondo {
  background-color: #f8e9eb;
}
.contenedor-ingresar {
  width: 90%;
  padding-top: 36px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8e9eb;
}
.contenedor-ingresar h3 {
  text-align: center;
}
.contenedor-ingresar h3 span {
  display: inline-block;
  border-bottom: 2px solid black;
  padding: 4px 6px;
}
.contenedor-ingresar form {
  max-width: 500px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
}

.contenedor-ingresar form input[type="email"],
.contenedor-ingresar form input[type="password"] {
  display: block;
  font-family: inherit;
  font-size: 15px;
  width: 100%;
  padding: 15px 5px 15px 20px;
  border: 1px solid #cabebf;
  margin-bottom: 19px;
  background: none;
}

.contenedor-ingresar form input[type="email"]::placeholder,
.contenedor-ingresar form input[type="password"]::placeholder {
  font-weight: bold;
  color: black;
}

.contenedor-ingresar form input[type="email"]:focus,
.contenedor-ingresar form input[type="password"]:focus {
  outline: none;
}

.contenedor-ingresar .contenedor-clave {
  font-size: 16px;
  line-height: 1.17;
  text-align: center;
}

.contenedor-ingresar p a {
  text-decoration: none;
}
.contenedor-ingresar form input[type="submit"] {
  padding: 15px 0 15px 0;
  font-family: inherit;
  border: 1px solid #181818;
  font-weight: 300;
  background-color: #181818;
  color: white;
  cursor: pointer;
  font-size: 17px;
  margin-bottom: 20px;
  width: 50%;
}
.contenedor-ingresar form input[type="submit"]:hover {
  background: rgb(85, 85, 85);
  color: white;
}
.contenedor-ingresar form p {
  padding: 10px 0px;
  font-size: 16px;
  line-height: 1.17;
  text-align: center;
}

.contenedor-ingresar form .boton-span {
  padding: 15px 10px 15px 10px;
  font-family: inherit;
  border: 1px solid #181818;
  font-weight: 300;
  background-color: #181818;
  cursor: pointer;
  font-size: 17px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contenedor-ingresar form .boton-span:hover {
  background: rgb(85, 85, 85);
}

.contenedor-ingresar form .boton-span a {
  color: white;
}

/*REGISTRAR*/
.contenedor-ingresar form label {
  display: flex;
  margin-top: 20px;
}

.contenedor-ingresar form label span {
  font-size: 12px;
  cursor: pointer;
  color: #594e45;
  font-weight: 100;
  letter-spacing: 2px;
  line-height: 1.7;
}

.contenedor-ingresar .contenedor-aceptar-terminos {
  margin-top: 20px;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  color: #594e45;
  margin-bottom: 40px;
  letter-spacing: 2px;
}
.contenedor-ingresar .enlace-politicas {
  margin-top: 20px;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  color: #594e45;
  margin-bottom: 40px;
  text-decoration: underline;
}
