.contenedor-productos-centrar {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  flex: wrap;
}

@media screen and (max-width: 920px) {
  .contenedor-productos-centrar {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@media screen and (max-width: 672px) {
  .contenedor-productos-centrar {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
