.grid-galeria {
  width: 100%;
  display: flex;
}

.grid-galeria-centrar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 320px);
  flex: wrap;
  width: 100%;
}

.contenedor-galerias-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor-galerias-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transform-origin: 0 0;
  transition: transform 0.25s, visibility 0.25s ease-in;
}

.contenedor-galerias-item:hover img {
  transform: scale(0.99);
}

.contenedor-galerias-item div {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
}

.contenedor-galerias-item p {
  color: #f2f2f1;
  font-size: 24px;
  line-height: 1.17;
  font-weight: 100;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .contenedor-galerias-item p {
    font-size: 18px;
  }
  .grid-galeria-centrar {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .contenedor-galerias-item img {
    height: 200px;
  }
}
