/**CONTENEDOR-PROMOCION*/
.contenedor-promocion {
  background-color: #dadad8;
  height: 47px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor-promocion p {
  font-size: 11px;
  letter-spacing: 1px;
}
