.contenedor-padre-modal {
  z-index: 900;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.137);
  display: flex;
  justify-content: center;
}

.contenedor-padre-modal::-webkit-scrollbar {
  display: none;
}

.contenedor-modal {
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 40px 0px;
  margin-top: 10px;
  z-index: 900;
  width: 80%;
  background-color: rgb(243, 239, 235);
  max-width: 600px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.modal-cerrar {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-cerrar button {
  text-decoration: none;
  border: none;
  list-style: none;
  background: inherit;
  cursor: pointer;
}

.contenedor-modal form {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.contenedor-modal form p {
  font-size: 14px;
  font-weight: bold;
}

.contenedor-modal form select,
.contenedor-modal form textarea,
.contenedor-modal form input[type="email"],
.contenedor-modal form input[type="number"],
.contenedor-modal form input[type="text"] {
  display: block;
  font-family: inherit;
  font-size: 12px;
  background-color: white;
  width: 100%;
  padding: 10px 5px;
  border: 1px solid #999;
}

.contenedor-modal form textarea,
.contenedor-modal form input[type="email"]:focus,
.contenedor-modal form input[type="text"]:focus {
  outline: none;
}

.contenedor-modal form input[type="submit"] {
  padding: 15px 0 15px 0;
  font-family: inherit;
  border: 1px solid #181818;
  font-weight: 300;
  background-color: #181818;
  color: white;
  cursor: pointer;
}

.contenedor-modal form input[type="submit"]:hover {
  background: rgb(85, 85, 85);
  color: white;
}

.contenedor-modal form .agregar-caracteristica {
  background-color: rgb(85, 85, 85);
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 10px 5px;
  cursor: pointer;
}

:root {
  --color-green: #00a878;
  --color-red: #fe5e41;
  --color-button: #fdffff;
  --color-black: #000;
}
.switch-button-modal {
  display: flex;
  position: relative;
  height: 20px;
}
.switch-button-modal .switch-button-checkbox {
  display: none;
}

.switch-button-modal .switch-button-label {
  background-color: var(--color-red);
  width: 100%;
  height: 20px;
  display: inline-block;
  position: absolute;
  cursor: pointer;

}

.switch-button-modal .switch-button-label:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);
}
.switch-button-modal
  .switch-button-checkbox-activo:checked
  + .switch-button-label {
  background-color: var(--color-green);
}
.switch-button-modal
  .switch-button-checkbox-activo:checked
  + .switch-button-label:before {
  right: 0px;
}

@media screen and (max-width: 600px) {
  .contenedor-modal {
    width: 90%;
  }
}
