.titulo-componente-2 {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding: 35px 10% 30px 10%;
}
.titulo-componente-2 h2 {
  font-size: 20px;
  margin: 40px 6% 0px 6%;
  text-align: center;
}

.titulo-componente-2 img {
  width: 54px;
}
