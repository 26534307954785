/*PRODUCTO SOLO*/
.contenedor-producto {
  padding: 7px;
  color: white;
  width: 100%;
  background-color: white;
}
.contenedor-producto .contenedor-producto-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
/*SLIDE PRODUCTO SOLO*/
.card-producto-slide {
  min-width: 25%;
  overflow: hidden;
  padding: 5px;
}

.card-producto-slide:hover,
.contenedor-producto:hover {
  background-color: rgb(248, 248, 248);
}
/**/
.card-producto-slide .slide-productos-imagen-item {
  width: 100%;
  object-fit: cover;
  height: 290px;
  display: block;
  margin: auto;
}

.slide-productos-botones {
  display: flex;
  justify-content: flex-end;
}

.slide-productos-botones img {
  cursor: pointer;
  width: 25px;
}

.card-producto-slide h3,
.contenedor-producto h3 {
  letter-spacing: 1px;
  margin-top: 2px;
  opacity: 0.8;
  font-size: 14px;
  line-height: 1.17;
  color: #181818;
}

.card-producto-slide p,
.contenedor-producto p {
  text-transform: uppercase;
  font-size: 11px;
  color: #181818;
  line-height: 2.17;
}

@media screen and (max-width: 920px) {
  .card-producto-slide {
    min-width: 50%;
  }
}
