/**FORMULARIO*/

.pie-registro-form{
  margin-top: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.pie-registro-form label,
.pie-registro-form input {
  font-size: 13px;
  margin: 10px 0;
}
.pie-registro-form label {
  display: flex;
  align-items: center;
}
.pie-registro-form label img {
  width: 20px;
  height: 20px;
}

.pie-registro-form input[type="email"] {
  display: block;
  font-family: inherit;
  font-size: 13px;
  background-color: #2b2b2b;
  width: 100%;
  padding: 0 0 8px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ffffff;
  margin-top: 20px;
  color: white;
}

.pie-registro-form input[type="email"]:focus {
  outline: none;
}

.pie-registro-form input[type="submit"] {
  padding: 10px 0 10px 0;
  font-family: inherit;
  margin-top: 5px;
  border: 1px solid #181818;
  font-weight: 300;
  width: 162px;
  color: #181818;
  cursor: pointer;
  background-color: #e59b9e;
}

.pie-registro-form input[type="submit"]:hover {
  background: rgb(255, 255, 255);
  color: #181818;
}

.contenedor-pie-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}

.contenedor-pie-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-pie {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /*top: 0;*/
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #e59b9e;
}

.contenedor-pie-check:hover input ~ .checkmark-pie {
  background-color: white;
}

.contenedor-pie-check input:checked ~ .checkmark-pie {
  background-color: rgb(236, 236, 236);
}

.checkmark-pie:after {
  content: "";
  position: absolute;
  display: none;
}

.contenedor-pie-check input:checked ~ .checkmark-pie:after {
  display: block;
}
