.titulo-componente {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10%;
}
.titulo-componente h2 {
  font-size: 22px;
  margin: 0px 8.4%;
  text-align: center;
}

.titulo-componente img {
  width: 70px;
}

@media screen and (max-width: 600px) {
  .titulo-componente img {
    width: 50px;
  }
  .titulo-componente h2 {
    font-size: 18px;
    margin: 0px 8.4%;
    text-align: center;
  }
}
