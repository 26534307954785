.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #989c9f;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.whatsapp img {
  width: 50px;
}
