.manifiesto {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "manifiesto-imagen manifiesto-contenido";
}

.manifiesto-imagen {
  grid-area: manifiesto-imagen;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manifiesto-imagen img {
  width: 480px;
}

.manifiesto-contenido {
  grid-area: manifiesto-contenido;
  padding: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.manifiesto-contenido h3 {
  font-size: 24px;
  line-height: 1.17;
  color: inherit;
}

.manifiesto-contenido h4 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 40px;
}

.manifiesto-contenido p {
  text-align: center;
  font-weight: 100;
  line-height: 1.7;
  color: #181818;
  font-size: 15px;
  margin-bottom: 30px;
}

.manifiesto-contenido a {
  background-color: #f2c9cd;
  border: 1px solid #181818;
  color: #181818;
  line-height: 1;
  font-size: 14px;
  font-weight: 300;
  transition: background-color 0.2s ease;
  cursor: pointer;
  padding: 16px;
}

.manifiesto-contenido a:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 800px) {
  .manifiesto {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "manifiesto-imagen"
      "manifiesto-contenido";
  }
}

@media screen and (max-width: 600px) {
  .manifiesto {
    grid-template-rows: auto 1fr;
  }
  .manifiesto-imagen img {
    width: 380px;
  }

  .manifiesto-contenido {
    padding: 0px 50px 20px 50px;
  }
}

@media screen and (max-width: 400px) {
  .manifiesto-imagen img {
    width: 340px;
  }
}
