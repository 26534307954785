.contenedor-mostrar-imagenes {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0px;
}

.contenedor-card-mostrar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 200px);
  gap: 15px;
  flex: wrap;
  width: 100%;
}

.contenedor-card-mostrar div {
  position: relative;
  display: flex;
}

.contenedor-card-mostrar .contenedor-card-imagen {
  width: 100%;
  object-fit: cover;
}

.contenedor-card-mostrar span {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.contenedor-card-mostrar span img {
  width: 20px;
}
