.contenedor-perfil {
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
}

.boton-menu-layout:hover {
  background-color: rgb(54, 54, 54);
}

.cabecera-perfil {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.cabecera-perfil h3 {
  font-size: 26px;
}

.cabecera-perfil p {
  margin-top: 15px;
  font-weight: 100;
  font-size: 14px;
  line-height: 1.7;
}

.grid-perfil {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: "grid-perfil-menu grid-perfil-detalles";
  gap: 20px;
  padding: 40px 5%;
  grid-column-gap: 40px;
}

.grid-perfil-menu {
  grid-area: grid-perfil-menu;
}

.grid-perfil-detalles {
  grid-area: grid-perfil-detalles;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.grid-perfil-menu ul {
  border-top: 1px solid #999;
  list-style-type: none;
  margin: 0 0 40px 0;
  padding: 0;
}
.grid-perfil-menu ul li {
  border-bottom: 1px solid #999;
  padding: 15px 0;
  list-style-type: none;
}
.grid-perfil-menu .boton-cerrar-sesion {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #181818;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  line-height: 1;
  font-size: 11px;
  font-weight: 300;
  transition: background-color 0.2s ease;
  cursor: pointer;
  padding: 16px;
  text-decoration: none;
}

.grid-perfil-menu .boton-cerrar-sesion:hover {
  background-color: #4b4a4a;
}

.boton-menu-layout {
  display: none;
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  color: #ffffff;
  padding: 2px 10px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .grid-perfil {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "grid-perfil-menu"
      "grid-perfil-detalles";
  }
  .boton-menu-layout {
    display: block;
  }
}
