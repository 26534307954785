.contenedor-direccion {
  display: flex;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

.contenedor-direccion input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.nuevaseleccion {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-color: #eee;
}

.contenedor-direccion:hover input ~ .nuevaseleccion {
  background-color: #ccc;
}

.contenedor-direccion input:checked ~ .nuevaseleccion {
  background-color: black;
}
.contenedor-datos-direccion {
  display: flex;
  flex-direction: column;
}

.contenedor-datos-direccion span {
  font-weight: bold;
}
