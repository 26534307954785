.contenedor-letras-palma {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.contenedor-letras-palma p {
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .contenedor-letras-palma .ocultar-computadora {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .contenedor-letras-palma .ocultar-tablet {
    display: none;
  }
}
