.contenedor-producto-solo {
  padding: 0 9% 0 9%;
}

.contenedor-grid-producto {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 38%;
  grid-template-areas: "grid-producto-imagen grid-producto-descripcion";
  gap: 30px;
}

.grid-producto-imagen {
  grid-area: grid-producto-imagen;
}

.grid-producto-descripcion {
  grid-area: grid-producto-descripcion;
}

.grid-producto-imagen-contenedor {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-areas: "grid-producto-imagen-contenedor-items grid-producto-imagen-contenedor-solo";
  gap: 10px;
}

.grid-producto-imagen-contenedor-items {
  grid-area: grid-producto-imagen-contenedor-items;
}

.grid-producto-imagen-contenedor-solo {
  grid-area: grid-producto-imagen-contenedor-solo;
}

.grid-producto-imagen-contenedor img {
  width: 100%;
}

.grid-producto-descripcion h1 {
  text-align: left;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: normal;
}
.grid-producto-descripcion .contenedor-producto-precio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.grid-producto-descripcion .contenedor-producto-precio span {
  font-size: 16px;
  line-height: 24px;
  margin-top: 7px;
  color: #181818;
  text-align: left;
}

/*CONTENEDOR CARACTERISTICAS*/
.contenedor-caracteristicas {
  margin-top: 15px;
}
.contenedor-caracteristicas img {
  width: 16px;
}
.contenedor-caracteristicas span {
  font-size: 14px;
  margin-left: 8px;
}
.caracteristicas-productos {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
/*CONTENEDOR COLOR*/
.grid-producto-descripcion .contenedor-producto-color {
  display: flex;
  align-items: center;
}
.grid-producto-descripcion .contenedor-producto-color label {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;
  color: inherit;
  margin-left: 5px;
  cursor: pointer;
  /*position: absolute;*/
}
.grid-producto-descripcion .contenedor-producto-color .labelselec {
  border: 1px solid #181818;
}
.grid-producto-descripcion .contenedor-producto-color label input {
  display: none;
}
.grid-producto-descripcion .contenedor-producto-color label span {
  height: 28px;
  width: 28px;
  display: block;
  background-color: #22221f;
  border-radius: 50%;
  position: relative;
}
/*CONTENEDOR TALLAS*/
.grid-producto-descripcion .contenedor-producto-tallas label {
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.grid-producto-descripcion .contenedor-producto-tallas input[type="radio"] {
  display: none;
}

.grid-producto-descripcion .contenedor-producto-tallas label span {
  /*background-color: #181818;*/
  /*color: #f4f3f3;*/
  height: 50px;
  width: 50px;
  font-size: 14px;
  border: 1px solid #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.grid-producto-descripcion .contenedor-producto-tallas .spanselect {
  background-color: #181818;
  color: #f4f3f3;
}
/*CONTENEDOR INCREMENTAR*/
.contenedor-incrementar {
  display: flex;
  background-color: #f2c9cd;
  height: 52px;
  margin-top: 35px;
  align-items: center;
}
.contenedor-incrementar .contenedor-incrementar-botones {
  display: flex;
  background-color: #ffffff;
  height: 40px;
  margin-left: 7px;
  align-items: center;
}

.contenedor-incrementar button {
  background: none;
  border: none;
  cursor: pointer;
}

.contenedor-incrementar .contenedor-incrementar-botones button,
.contenedor-incrementar .contenedor-incrementar-botones span {
  margin: 0px 14px;
}

.contenedor-incrementar .contenedor-incrementar-texto {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contenedor-incrementar .contenedor-incrementar-texto span {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

/*CONTENEDOR AGREGAR FAVORITOS*/
.contenedor-agregar-favorito {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 19px;
}
.contenedor-agregar-favorito img {
  width: 22px;
  cursor: pointer;
}
.contenedor-agregar-favorito span {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 2px;
  margin-left: 5px;
}

/*CONTENEDOR PRODUCTOS DESCRIPCION*/
.grid-producto-descripcion .contenedor-producto-descripcion {
  margin-top: 33px;
}
.grid-producto-descripcion .contenedor-producto-descripcion span {
  font-weight: 100;
  font-size: 14px;
  line-height: 1.7;
}

/*CONTENEDOR LINK PRODUCTOS*/
.contenedor-link-producto {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding-top: 40px;
}
.contenedor-link-producto span {
  margin: 0px 5% 0px 5%;
  font-size: 24px;
}

@media screen and (max-width: 800px) {
  .contenedor-grid-producto {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "grid-producto-imagen"
      "grid-producto-descripcion";
  }

  .grid-producto-descripcion {
    grid-area: grid-producto-descripcion;
    max-width: 100%;
  }
}
