.contenedor-guardados-cliente h4 {
  color: #999;
  font-size: 17.33px;
  margin-bottom: 10px;
}

.contenedor-guardados-cliente .contenedor-guardados-productos {
  display: flex;
  width: 100%;
  padding: 15px 0;
  position: relative;
  border-bottom: 1px solid #999;

}

.contenedor-guardados-cliente .guardados-imagen {
  width: 25%;
}

.contenedor-guardados-cliente .guardados-descripcion {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: space-between;
}

.contenedor-guardados-cliente .guardados-imagen a {
  text-decoration: none;
  color: inherit;
}
.contenedor-guardados-cliente .guardados-imagen img {
  width: 100%;
  height: auto;
}

.contenedor-guardados-cliente .guardados-descripcion h5 {
  font-size: 12px;
  margin-bottom: 5px;
}
.contenedor-guardados-cliente .guardados-descripcion span {
  font-size: 12px;
}
.contenedor-guardados-cliente .guardados-descripcion .guardados-descripcion-botones {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.contenedor-guardados-cliente .guardados-descripcion select {
  border: 1px solid #999;
  padding: 8px 30px 8px 10px;
  background: url("/public/icons/select.svg");
  background-position: calc(100% - 12px) center;
  background-size: 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  border-radius: 0;
  width: 48%;
}
.contenedor-guardados-cliente .guardados-descripcion button {
  border-color: #999;
  padding: 8px 30px 8px 10px;
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #181818;
  color: #181818;
  font-weight: 300;
  transition: background-color 0.2s ease;
  cursor: pointer;
  width: 48%;
}

.contenedor-guardados-cliente .guardados-descripcion button:hover {
  background-color: #4b4a4a;
  color: white;
}


.contenedor-guardados-cliente .guardados-descripcion-borrar {
  display: flex;
  justify-content: flex-end;
}

.contenedor-guardados-cliente .guardados-descripcion-borrar img{
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .contenedor-guardados-cliente .guardados-descripcion .guardados-descripcion-botones {
    display: flex;
    flex-direction: column;
  }
  .contenedor-guardados-cliente .guardados-descripcion select {   
    width: 100%;
    margin: 5px 0;
  }
  .contenedor-guardados-cliente .guardados-descripcion button {
    width: 100%;

  }
}

