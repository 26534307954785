.titulo-2-componente {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 10%;
}
.titulo-2-componente h2 {
  font-size: 20px;
  margin: 0px 6%;
  text-align: center;
}

.titulo-2-componente img {
  width: 58px;
}

@media screen and (max-width: 600px) {
  .titulo-2-componente img {
    width: 50px;
  }
  .titulo-2-componente h2 {
    font-size: 16px;
    margin: 0px 8.4%;
    text-align: center;
  }
}
