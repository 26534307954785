/**DERECHOS DE AUTOR*/
.contenedor-pie-derechos {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2b2b;
  font-size: 13px;
  color: white;
  border-top: 1px solid #606060;
}
.contenedor-pie-derechos img {
  margin: 0 0 0px 8px;
  width: 50px;
}