.no-existe {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.contenedor-grid-carrito {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "grid-carrito-productos grid-carrito-pagar";
  gap: 20px;
  padding: 20px;
}

.grid-carrito-productos {
  grid-area: grid-carrito-productos;
}

.grid-carrito-pagar {
  grid-area: grid-carrito-pagar;
}

@media screen and (max-width: 800px) {
  .contenedor-grid-carrito {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "grid-carrito-productos"
      "grid-carrito-pagar";
  }
}
