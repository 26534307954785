/*.task-container {
  margin: 0;
  list-style: none;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
}

.task-item {
  display: flex;
  width: 200px;
  flex-direction: column;
  background-color: red;
  margin: 5px;
}*/

/*.contenedor-submenu-administrador {
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  background-color: green;
}
*/

.contenedor-direcciones-drop li {
  list-style: none;
  font-size: 12px;

  padding: 0.3em 0.5em;
  margin: 15px 0px;
  background-color: #f3efeb;
}
.contenedor-direcciones-drop li p {
  font-weight: bold;
}
.contenedor-direcciones-drop li div img {
  width: 20px;
  cursor: pointer;
}

.contenedor-drop-sub {
  display: flex;
  justify-content: space-between;
  padding: 0.3em 0.5em;
  margin: 2px 0px;
  background-color: white;
}

.contenedor-botones-drop {
  display: flex;
  flex-direction: column;
}
.contenedor-botones-drop span {
  text-align: center;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.drop-botones-drop-menu {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.drop-botones-drop-menu span {
  width: 49%;
}

.boton-drop-control {
  background-color: inherit;
  cursor: pointer;
  border: none;
}

.drop-botones-guardar,
.drop-botones-agregar {
  font-size: 12px;
  text-align: center;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top: 8px;
}
