/***CONTENEDOR TITULO***/
.contenedor-titulo {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

/*Titulo para contenedores h2*/
.titulo {
  font-size: 22px;
  line-height: 1.3;
}

.titulo-panel-administracion {
  color: #999;
  font-size: 17.33px;
  margin-bottom: 10px;
}

.boton-administracion {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #181818;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  line-height: 1;
  font-size: 11px;
  font-weight: 300;
  transition: background-color 0.2s ease;
  cursor: pointer;
  padding: 16px;
  text-decoration: none;
}

.boton-administracion:hover {
  background-color: #4b4a4a;
}
