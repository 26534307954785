.contenedor-etiquetas{
  position: relative;
}

.ocultar-contenedor-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.contenedor-check-etiquetas {
  display: none;
  border: 1px #737373 solid;
  background-color: white;
  font-size: 12px;
}

.contenedor-check-etiquetas label {
  display: block;
  cursor: pointer;
}

.contenedor-check-etiquetas label:hover {
  background-color: #737373;
}
