/** TABLA **/
.tabla-producto-checkout {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #f4f3f3;
}

.tabla-producto-checkout thead tr th {
  padding: 17px;
}
.tabla-producto-checkout td,
.tabla-producto-checkout th {
  border-bottom: 1px solid #ccc;
  padding: 12px;
  text-align: center;
}

.tabla-producto-checkout tbody input[type="text"],
.tabla-producto-checkout input[type="number"] {
  width: 100px;
}

.tabla-producto-checkout input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

.tabla-producto-checkout td .imagen-tabla {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.tabla-producto-checkout tbody tr:hover {
  background-color: rgb(248, 248, 248);
}

.tabla-producto-checkout tbody p {
  font-weight: bold;
  text-align: start;
}

.tabla-producto-checkout td button {
  padding: 5px;
  margin: 0 4px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  border-radius: 0.2rem;
}

.tabla-producto-checkout .datos-producto-linea {
  display: flex;
}

.tabla-producto-checkout .controles-producto-linea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
}
@media screen and (max-width: 800px) {
  .contenedor-celular-productos {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 700px) {
  .tabla-producto-checkout td .imagen-tabla {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
