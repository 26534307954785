/**INICIO GRID MENU PRINCIPAL **/
.grid-menu-principal {
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 900;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 100px;
  grid-template-areas: "grid-menu-links grid-menu-logo grid-menu-iconos";
  background: #eeeeee;
  /*box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);*/
}

/**CONTENEDOR MENU HAMBURGUESA PARA CELULAR**/
.contenedor-menu-hamburguesa {
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  cursor: pointer;
}

/**GRID DE MENU LINKS**/
.grid-menu-links {
  grid-area: grid-menu-links;
  display: flex;
  align-items: center;
  /*justify-content: space-around;*/
  justify-content: center;
  gap: 30px;
}

.grid-menu-links a,
.grid-menu-iconos a {
  color: #181818;
  /*border: 1px solid transparent;*/
  border-radius: 3px;
  font-size: 13px;
  line-height: 1.7;
}

/**GRID DE MENU LINKS - BUSCAR**/
.grid-menu-links .formulario-buscar {
  display: none;
  width: 100%;
  justify-content: center;
  padding: 15px 0;
  background-color: #999;
}

.grid-menu-links .formulario-buscar input[type="search"] {
  display: block;
  font-family: inherit;
  background-color: #999;
  height: 40px;
  padding-left: 20px;
  width: 100%;
  border: none;
  outline: 0;
  color: #f2f2f1;
  font-size: 16px;
}

.grid-menu-links .formulario-buscar button {
  background-color: #999;
  height: 40px;
  padding: 5px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 40px;
  cursor: pointer;
  border: none;
}

/**GRID DE LOGO**/
.grid-menu-logo {
  grid-area: grid-menu-logo;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-menu-logo img {
  width: 140px;
}

/**GRID DE ICONOS**/
.grid-menu-iconos {
  grid-area: grid-menu-iconos;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/**FIN GRID MENU PRINCIPAL **/

/**GRID SUBMENU**/
.grid-sub-menu {
  display: none;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: auto;
  padding: 30px;
  width: 100%;
  position: fixed;
  z-index: 900;
  background-color: #f2f2f1;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
}

.grid-sub-menu-activo {
  display: grid;
}

/**GRID CONTENEDOR SUB MENU LINKS**/
.grid-contenedor-sub-menu-links {
  display: flex;
  grid-gap: 50px;
  flex-direction: column;
  align-items: flex-start;
}

.grid-columna-sub-menu {
  max-height: 255px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-gap: 0 40px;
  padding-top: 7px;
}

.grid-columna-sub-menu p {
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
}

/**GRID CONTENEDOR SUB MENU IMAGENES**/
.grid-contenedor-sub-menu-imagenes {
  flex: 1;
  display: flex;
  grid-gap: 30px;
  justify-content: flex-end;
}
.grid-contenedor-sub-menu-imagenes a {
  position: relative;
}

.grid-contenedor-sub-menu-imagenes .grid-contenedor-producto {
  position: relative;
  overflow: hidden;
  height: max-content;
  padding-top: initial;
  width: 100%;
  border-radius: 0;
  max-width: 300px;
  max-height: 300px;
  cursor: pointer;
}

.grid-contenedor-sub-menu-imagenes .grid-contenedor-producto img {
  width: 100%;
  height: 100%;
  position: initial;
  top: 0;
  left: 0;
  object-fit: cover;
}

.grid-contenedor-sub-menu-imagenes p {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  font-weight: 600;
  text-align: left;
}
@media screen and (max-width: 940px) {
  .grid-sub-menu {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  /**CONTENEDOR MENU HAMBURGUESA PARA CELULAR**/
  .contenedor-menu-hamburguesa {
    display: flex;
  }

  /**GRID DE MENU LINKS**/
  .grid-menu-links {
    background-color: #f2f2f1;
    top: 100px;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s ease all;
    transform: translate(-100%);
  }

  .grid-menu-links-activo {
    transform: translate(0%);
  }

  /**GRID DE MENU LINKS - BUSCAR**/
  .grid-menu-links .formulario-buscar {
    display: flex;
  }

  /**GRID SUBMENU**/
  .grid-sub-menu {
    display: none;
  }

  /**SUB MENU CELULAR**/
  .menu-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
  }

  .menu-links-cabecera {
    font-size: 16px;
    padding: 18px 0;
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  .menu-links-iconos {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .menu-links-iconos a {
    font-size: 14px;
    padding: 18px 0;
  }

  .menu-link-submenu {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
  }

  .menu-link-submenu-activo {
    display: flex;
  }

  .menu-link-submenu p {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
  }
}
