* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/*@font-face {
  font-family: "AlikeRegular";
  src: local("AlikeRegular"),
    url("./fonts/alike-regular.ttf") format("truetype");
  font-weight: normal;
}*/
@font-face {
  font-family: "Pressura";
  src: local("Pressura"),
    url("./fonts/GT-Pressura-Mono.otf") format("truetype");
  font-weight: normal;
}

body {
  font-family: Pressura, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #000;

}

a:visited {
  color: #000;
}