.controles-tienda {
  position: relative;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  min-width: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 0px;
}
.controles-tienda .controles-filtros {
  display: flex;
}

.controles-tienda button {
  padding: 2px 22px 2px 22px;
  font-family: inherit;
  border: 1px solid #181818;
  font-weight: 300;
  background-color: #181818;
  color: white;
  font-size: 11px;
  cursor: pointer;
}

.controles-tienda button:hover {
  background: rgb(85, 85, 85);
  color: white;
}

.controles-tienda select {
  display: block;
  font-family: inherit;
  font-size: 11px;
  background-color: white;
  margin: 0px 5px 5px 5px;
  border: none;
}

.controles-tienda select:focus {
  outline: none;
}

@media screen and (max-width: 800px) {
  .controles-tienda {
    flex-direction: column;
    min-height: 52px;
    justify-content: center;
  }

  .controles-tienda .controles-filtros {
    width: 100%;
    justify-content: space-between;
  }

  .controles-tienda button {
    margin-top: 5px;
  }
}

@media screen and (max-width: 600px) {
  .controles-tienda select {
    width: 50%;
  }
}
