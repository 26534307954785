/**GRID DE MENU LINKS**/
.contenedor-busqueda {
  background-color: #f4f3f3;
  top: -200px;
  position: fixed;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s ease all;
  transform: translateY(300px);
  z-index: 800;
  padding: 30px 60px;
}

.contenedor-busqueda-activo {
  /*transform: translate(0%);*/
  transform: none;
}

.contenedor-busqueda form {
  display: flex;
  width: 100%;
}

.contenedor-busqueda form input[type="search"] {
  display: block;
  font-family: inherit;
  font-size: 32px;
  background-color: #f4f3f3;
  width: 100%;
  padding: 0 0 8px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #999;
  margin-top: 20px;
}

.contenedor-busqueda form input[type="search"]:focus {
  outline: none;
}

.contenedor-busqueda form button {
  background-color: inherit;
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 800px) {
  .contenedor-busqueda {
    display: none;
  }
}
