.contenedor-instagram {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
  gap: 5px;
  padding: 5px;
}
.contenedor-post-instagram {
  position: relative;
}

.contenedor-instagram img:hover {
  transform: scale(1.02);
}
.contenedor-instagram .instagram-imagen {
  width: 100%;
  height: 200px;
  /*height: auto;*/
  display: block;
  transition: all 100ms ease-out;
  cursor: pointer;
  object-fit: cover;
}

.contenedor-instagram .instagram-video {
  width: 100%;
  height: 200px;
  /*height: auto;*/
  display: block;
  transition: all 100ms ease-out;
  cursor: pointer;
  object-fit: cover;
}

.contenedor-instagram .instagram-icono {
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 10px 5px 5px;
}

.contenedor-instagram .instagram-icono img {
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 1100px) {
  .contenedor-instagram .instagram-imagen {
    height: 150px;
  }

  .contenedor-instagram .instagram-video {
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .contenedor-instagram {
    grid-template-columns: repeat(3, 1fr);
  }

  .contenedor-instagram .instagram-icono {
    padding: 5px 5px 5px 5px;
  }
  .contenedor-instagram .instagram-icono img {
    width: 15px;
    height: 15px;
  }
  .contenedor-instagram .instagram-imagen {
    height: 100px;
  }

  .contenedor-instagram .instagram-video {
    height: 100px;
  }

  .contenedor-post-instagram:nth-child(10),
  .contenedor-post-instagram:nth-child(11),
  .contenedor-post-instagram:nth-child(12) {
    display: none;
  }
}
