  .task-container {
    margin: 0;
    list-style: none;
    padding: 5px;
    border-radius: 5px;  
    display: inline-flex;
    flex-direction: column;
  }
  
  .task-item {
      display: flex;
      width: 200px;
      flex-direction: column;
      background-color: red;
      margin: 5px;
    /*color: rgb(250, 82, 52);
    box-shadow: 0px 2px 4px #4a4c4e;
    border-radius: 5px;
    padding: 0.3em 0.5em;
    background-color: whitesmoke;
    text-align: center;
    margin: 5px;
    border: 2px solid transparent;*/
  }

  .contenedor-submenu-administrador{
      display: flex;
      margin-left: 10px;
      flex-direction: column;
      background-color: green;
  }
  
  .task-item:focus {
    outline: none;
    border: 2px solid #4a9af5;
  }