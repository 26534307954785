/**GRID DE MENU LINKS**/
.contenedor-carrito {
  background-color: #fff;
  top: 0;
  position: fixed;
  right: 0%;
  width: 650px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s ease all;
  transform: translate(100%);
  z-index: 950;
  padding: 30px 60px;
  box-shadow: 15px 0 20px 5px #181818;
}

.contenedor-carrito::-webkit-scrollbar {
  display: none;
}

.contenedor-carrito-activo {
  /*transform: translate(0%);*/
  transform: none;
}

.contenedor-carrito .carrito-cabecera {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.contenedor-carrito .carrito-cabecera span {
  line-height: 1.17;
  font-size: 26px;
  font-weight: 100;
}

.contenedor-carrito .carrito-contenido {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  justify-content: space-between;
  width: 100%;
}
.contenedor-carrito .carrito-productos {
  padding: 20px 0;
  display: flex;
}
.contenedor-carrito .carrito-detalles {
  border-top: 1px solid #999;
  padding: 20px 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.contenedor-carrito .carrito-detalles .carrito-detalles-contenido {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.contenedor-carrito .carrito-detalles .carrito-detalles-botones {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.contenedor-carrito .carrito-detalles .carrito-detalles-botones p {
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  font-size: 11px;
  font-weight: 300;
  transition: background-color 0.2s ease;
  cursor: pointer;
  padding: 16px;
}
.contenedor-carrito .carrito-detalles-botones-comprar {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  color: #ffffff;
}
.contenedor-carrito .carrito-detalles-botones-seguir {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  color: #000000;
}
.contenedor-carrito .carrito-detalles-botones-comprar:hover {
  background-color: rgb(54, 54, 54);
}
.contenedor-carrito .carrito-detalles-botones-seguir:hover {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(255, 255, 255);
  color: #ffffff;
}

.contenedor-carrito .carrito-producto-item {
  display: flex;
  font-size: 12px;
  margin-bottom: 20px;
}

.contenedor-carrito .carrito-item-imagen {
  width: 80px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
}

.contenedor-carrito .carrito-item-imagen img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}
.contenedor-carrito .carrito-producto-descripcion {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contenedor-carrito .carrito-contenedor-info {
  display: flex;
  justify-content: space-between;
}
.contenedor-carrito .carrito-descripcion-info {
  display: flex;
  flex-direction: column;
}

.contenedor-carrito .carrito-descripcion-info p{
font-size: 13px;
margin-bottom: 5px;
}

.contenedor-carrito .carrito-descripcion-info span{
 font-weight: bold;
  }

.contenedor-carrito .carrito-contenedor-controles {
  display: flex;
  justify-content: space-between;
}

.contenedor-carrito .carrito-contenedor-controles select{
  border: 1px solid #999;
    padding: 8px 30px 8px 10px;
  background: url("/public/icons/select.svg");
  background-position: calc(100% - 12px) center;
  background-size: 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  border-radius: 0;
}





@media screen and (max-width: 800px) {
  .contenedor-carrito {
    width: 100%;
  }

  .contenedor-carrito .carrito-productos {
    flex-direction: column;
  }
}
