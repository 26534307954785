.contenedor-notificacion {
  z-index: 900;
  position: fixed;
  background-color: rgb(243, 239, 235);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 80px;
  right: 0;
  width: 200px;
  padding: 10px;
}
